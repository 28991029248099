.ribbon-backdrop {
  z-index: 11;
}

.renewalPackDetailWrapper-drop {
  pointer-events: none;
  background: #fdf4e5;
  z-index: 13;
}

.backdrop-active {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black backdrop */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.popup {
  width: 281px;
  position: relative;
  z-index: 999;
}
.popup:after {
  content: '';
  height: 100%;
  width: 100%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
}
.popup:before {
  content: '';
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
  background: #0057ac;
  position: absolute;
  z-index: 998;
}
/*
Different arrow positioning
*/
.popup.arrow:before {
  left: calc(100% - 17px);
  top: 11px;
}
.popup.arrow-top:before {
  left: calc(50% - 10px);
  top: -8px;
}
.popup.arrow-right:before {
  top: calc(26% - 10px);
  right: -4px;
}
.popup.arrow-bottom:before {
  left: calc(50% - 10px);
  bottom: -8px;
}
.popup.arrow-left:before {
  top: calc(50% - 10px);
  left: -8px;
}

@media screen and (min-width: 768px) {
  .popup {
    width: 281px;
  }

  .popup.arrow:before {
    left: calc(93% - 11px);
    top: -3px;
  }

  .ribbon-backdrop {
    z-index: 14;
  }
}

.popup-centurion {
  width: 281px;
  position: relative;
  z-index: 999;
}
.popup-centurion:after {
  content: '';
  height: 100%;
  width: 100%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
}
.popup-centurion:before {
  content: '';
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  box-shadow: 0 1px 14px rgba(0, 0, 0, 0.2);
  background: #0057ac;
  position: absolute;
  z-index: 998;
}
/*
Different arrow positioning
*/
.popup-centurion.arrow:before {
  left: calc(100% - 140px);
  top: 140px;
}

.popup-centurion.arrow-landing-page:before {
  left: calc(93% - 136px);
  bottom: -7px;
}

.popup-centurion.arrow-order-detail-step-0:before {
  left: calc(93% - 122px);
  top: -8px;
}

.popup-centurion.arrow-order-detail-step-1:before {
  left: calc(93% - 122px);
  top: -7px;
}

.popup-centurion.arrow-order-detail-step-2:before {
  left: calc(93% - 122px);
  top: unset;
  bottom: -7px;
}

.popup-centurion.arrow-order-detail-step-3:before {
  left: calc(93% - 124px);
  top: unset;
  bottom: -7px;
}

.popup-centurion.arrow-order-detail-step-4:before {
  left: calc(93% - 124px);
  top: unset;
  bottom: -7px;
}

.popup-centurion.arrow-order-detail-step-5:before {
  left: calc(93% - 120px);
  top: unset;
  bottom: -7px;
}

.popup-centurion.arrow-product-cart-step-0:before {
  left: calc(93% - 120px);
  top: 116px;
}

.popup-centurion.arrow-product-cart-step-1:before {
  left: calc(93% - 120px);
  top: 98px;
}

.popup-centurion.arrow-order-summary-step-0:before {
  left: calc(93% - 120px);
  top: unset;
  bottom: -7px;
}

.popup-centurion.arrow-order-summary-step-1:before {
  left: calc(93% - 120px);
  top: unset;
  bottom: -7px;
}

@media screen and (min-width: 768px) {
  .popup-centurion {
    width: 281px;
  }

  .popup-centurion.arrow:before {
    left: calc(93% - 116px);
    top: 134px;
  }

  .popup-centurion.arrow-landing-page:before {
    left: calc(93% - 116px);
    bottom: -7px;
  }

  .popup-centurion.arrow-order-detail-step-0:before {
    left: calc(93% - 122px);
    top: -8px;
  }

  .popup-centurion.arrow-order-detail-step-1:before {
    left: calc(93% - 122px);
    top: -7px;
  }

  .popup-centurion.arrow-order-detail-step-2:before {
    left: calc(93% - 122px);
    top: unset;
    bottom: -7px;
  }

  .popup-centurion.arrow-order-detail-step-3:before {
    left: calc(93% - 124px);
    top: unset;
    bottom: -7px;
  }

  .popup-centurion.arrow-order-detail-step-4:before {
    left: calc(93% - 124px);
    bottom: -7px;
    top: unset;
  }

  .popup-centurion.arrow-order-detail-step-5:before {
    left: calc(93% - 120px);
    bottom: -7px;
    top: unset;
  }

  .popup-centurion.arrow-product-cart-step-0:before {
    left: calc(93% - 120px);
    top: 116px;
  }

  .popup-centurion.arrow-product-cart-step-1:before {
    left: calc(93% - 120px);
    top: 98px;
  }

  .popup-centurion.arrow-order-summary-step-0:before {
    left: calc(93% - 120px);
    top: -8px;
  }

  .popup-centurion.arrow-order-summary-step-1:before {
    left: calc(93% - 120px);
    top: unset;
    bottom: -7px;
  }
}

@media screen and (min-width: 1366px) {
  .popup-centurion {
    width: 281px;
  }

  .popup-centurion.arrow:before {
    left: calc(93% - 116px);
    top: 134px;
  }

  .popup-centurion.arrow-landing-page:before {
    left: calc(93% - 116px);
    bottom: -7px;
  }

  .popup-centurion.arrow-order-detail-step-0:before {
    left: calc(93% - 122px);
    top: -8px;
  }

  .popup-centurion.arrow-order-detail-step-1:before {
    left: calc(93% - 122px);
    bottom: -7px;
    top: unset;
  }

  .popup-centurion.arrow-order-detail-step-2:before {
    left: calc(93% - 122px);
    top: unset;
    bottom: -7px;
  }

  .popup-centurion.arrow-order-detail-step-3:before {
    left: calc(93% - 124px);
    top: -8px;
  }

  .popup-centurion.arrow-order-detail-step-4:before {
    left: calc(93% - 124px);
    top: -8px;
  }

  .popup-centurion.arrow-order-detail-step-5:before {
    left: calc(93% - 120px);
    top: -8px;
  }

  .popup-centurion.arrow-product-cart-step-0:before {
    left: calc(93% - 120px);
    top: 116px;
  }

  .popup-centurion.arrow-product-cart-step-1:before {
    left: calc(93% - 120px);
    top: 98px;
  }

  .popup-centurion.arrow-order-summary-step-0:before {
    left: calc(93% - 120px);
    top: -8px;
  }

  .popup-centurion.arrow-order-summary-step-1:before {
    left: calc(93% - 120px);
    top: unset;
    bottom: -7px;
  }
}
