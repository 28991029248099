.product-cart-product-name {
  color: #003764;
  font-family: Prompt;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
}

.product-cart-product-price-and-pv {
  color: var(--003-b-6-f, #003B6F);
  font-family: Prompt;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product-cart-product-item-code {
  color: var(--FG1, #8A9BB0);
  font-family: Prompt;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.product-cart-style-product-name-price-and-pv {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.product-cart-additional {
  color: var(--Dark-Blue, #153862);
  font-feature-settings: 'clig' off, 
  'liga' off;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.icon-plus-add-more-products {
  svg {
      path {
          stroke: #fff;
          font-weight: 'bold';
      }
  }
  margin-right: 5px;
  display: flex;
  align-items: center;
}
