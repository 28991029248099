/* base fonts for our website */
@font-face {
  font-family: 'Prompt';
  font-weight: 300;
  font-style: normal;
  src: url(../public/assets/Fonts/Prompt/Prompt-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Prompt';
  font-weight: 300;
  font-style: italic;
  src: url(../public/assets/Fonts/Prompt/Prompt-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Prompt';
  font-weight: 400;
  font-style: normal;
  src: url(../public/assets/Fonts/Prompt/Prompt-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Prompt';
  font-weight: 400;
  font-style: italic;
  src: url(../public/assets/Fonts/Prompt/Prompt-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Prompt';
  src: url(../public/assets/Fonts/Prompt/Prompt-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Prompt';
  src: url(../public/assets/Fonts/Prompt/Prompt-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'Prompt';
  src: url(../public/assets/Fonts/Prompt/Prompt-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}

html,
body {
  margin: 0;
  font-family: 'Prompt';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d3d9e1;
}

.custom-body html,
body {
  width: auto !important;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Mobi scroll modal */
.mbsc-ios .mbsc-fr-w {
  font-family: 'Prompt' !important;
}

.mbsc-ios .mbsc-fr-btn-s .mbsc-fr-btn {
  font-weight: 400 !important;
}

::-webkit-scrollbar {
  display: none;
}

.mbsc-ios .mbsc-fr-overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}
